import StatisticsService from "@/services/StatisticsService";

const Statistics = {
  namespaced: true,
  state: {
    all: {},
  },
  getters: {
    all: (state: { all: any }) => state.all,
  },
  mutations: {
    SET_ALL(state: { all: any }, all: any) {
      state.all = all
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const statistics = await StatisticsService.fetchAll();
      commit("SET_ALL", statistics);
    },
  }
} as any

export default Statistics