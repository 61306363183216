import SettingsService from "@/services/SettingsService";

const Pages = {
  namespaced: true,
  state: {
    pages: [],
  },
  getters: {
    all: (state: { pages: any }) => state.pages,
  },
  mutations: {
    SET_ALL(state: { pages: any }, pages: any) {
      state.pages = pages
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
        const pages = await SettingsService.fetchOnePublic("pages");
        commit("SET_ALL", pages.setting_value);
      },
    async update({ commit }: any, data: any) {
      const response = await SettingsService.update("pages", data);
    }
  }
} as any

export default Pages