<template>
  <div id="custom_fc_button" style="visibility: hidden">
    <a id="open_fc_widget" @click="openWidget()" style="cursor: pointer"
      ><img id="imageicon" :src="this.freshchat.appLogo"
    /></a>
  </div>
</template>
<script>
export default {
  mounted() {
    // const script = document.createElement('script')
    // script.async = true
    // script.src = 'https://wchat.freshchat.com/js/widget.js'
    // const firstScript = document.getElementsByTagName('script')[0]
    // firstScript.parentNode.insertBefore(script, firstScript)

    window.fcWidget.init({
      token: this.freshchat.appToken,
      host: this.freshchat.host,
      config: {
        cssNames: {
          widget: "custom_fc_frame",
          open: "custom_fc_open",
          expanded: "custom_fc_expanded",
        },
        showFAQOnOpen: true,
        hideFAQ: true,
        agent: {
          hideName: false,
          hidePic: false,
          hideBio: false,
        },
        headerProperty: {
          //If you have multiple sites you can use the appName and appLogo to overwrite the values.
          hideChatButton: true,
          appName: this.freshchat.appName,
          appLogo: this.baseUrl + this.freshchat.appLogo,
          backgroundColor: this.freshchat.backgroundColor,
          foregroundColor: this.freshchat.foregroundColor,
          backgroundImage: this.freshchat.backgroundImage,
        },
        content: {
          placeholders: {
            search_field: "Search",
            reply_field: "Reply",
            csat_reply: "Add your comments here",
          },
          actions: {
            csat_yes: "Yes",
            csat_no: "No",
            push_notify_yes: "Yes",
            push_notify_no: "No",
            tab_faq: "Solutions",
            tab_chat: "Chat",
            csat_submit: "Submit",
          },
          headers: {
            chat: "Chat with Us",
            chat_help: "Reach out to us if you have any questions",
            faq: "Solution Articles",
            faq_help: "Browse our articles",
            faq_not_available: "No Articles Found",
            faq_search_not_available: "No articles were found for {{query}}",
            faq_useful: "Was this article helpful?",
            faq_thankyou: "Thank you for your feedback",
            faq_message_us: "Message Us",
            push_notification:
              "Don't miss out on any replies! Allow push notifications?",
            csat_question: "Did we address your concerns??",
            csat_yes_question: "How would you rate this interaction?",
            csat_no_question: "How could we have helped better?",
            csat_thankyou: "Thanks for the response",
            csat_rate_here: "Submit your rating here",
            channel_response: {
              offline: "We are currently away. Please leave us a message",
              online: {
                minutes: {
                  one: "Currently replying in {!time!} minutes ",
                  more: "Typically replies in {!time!} minutes",
                },
                hours: {
                  one: "Currently replying in under an hour",
                  more: "Typically replies in {!time!} hours",
                },
              },
            },
          },
        },
      },
    });

    window.fcWidget.user.setProperties({
      firstName: this.currentUser.first_name,
      lastName: this.currentUser.last_name,
      email: this.currentUser.email,
    });

    window.fcWidget.on("widget:loaded", function () {
      document.getElementById("custom_fc_button").style.visibility = "visible";
      window.fcWidget.on("unreadCount:notify", function (resp) {
        console.log(resp);
        if (resp.count > 0) {
          // document.getElementById('notify').classList.add('h-btn-notify');
          document.getElementById("notify").style.visibility = "visible";
        } else if (resp.count == 0) {
          // document.getElementById('notify').classList.remove('h-btn-notify');
          document.getElementById("notify").style.visibility = "hidden";
        }
      });
      window.fcWidget.on("widget:closed", function () {
        document.getElementById("fc_frame").style.visibility = "hidden";
        document.getElementById("open_fc_widget").style.visibility = "visible";
      });
      window.fcWidget.on("widget:opened", function (resp) {
        document.getElementById("open_fc_widget").style.visibility = "hidden";
      });
    });
  },
  computed: {
    freshchat() {
      return this.$store.state.Freshchat.all;
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    baseUrl() {
      return window.location.origin;
    },
  },
  methods: {
    openWidget() {
      document.getElementById("fc_frame").style.visibility = "visible";
      window.fcWidget.open();
    },
  },
};
</script>
<style scoped>
#custom_fc_button {
  width: 10%;
  position: fixed;
  right: 1px;
  bottom: 20px;
}
#imageicon {
  height: 30%;
  width: 30%;
}
</style>