import MailsService from "@/services/MailsService";

const Mails = {
  namespaced: true,
  state: {
    mails: {},
    mail: [],
    filteredMails: [],
  },
  getters: {
    all: (state: { mails: any }) => state.mails,
  },
  mutations: {
    SET_CURRENT(state: { mail: any }, mail: any) {
      state.mail = mail;
    },
    SET_ALL(state: { mails: any }, mails: any) {
      state.mails = mails;
    },
    SET_FILTERED(state: { filteredMails: any }, mails: any) {
      state.filteredMails = mails;
    },
    EMPTY_CURRENT(state: { mail: any }) {
      state.mail = {
        subject: "",
        greeting: "",
        before_is_html: false,
        before_text: "",
        action_url: "",
        action_text: "",
        after_is_html: false,
        after_text: "",
        ending: "",
      };
    },
  },
  actions: {
    async fetchById({ commit }: any, id: string) {
      const mail = await MailsService.fetchOne(id);
      commit("SET_CURRENT", mail);
    },
    async fetchAll({ commit }: any, filter: any) {
      const mails = await MailsService.fetchAll();
      commit("SET_ALL", mails);
    },
    async fetchByTemplate({ commit }: any, template: string) {
      const mails = await MailsService.fetchByTemplate(template);
      commit("SET_FILTERED", mails);
    },
    async fetchByRecipient({ commit }: any, id: string) {
      const mails = await MailsService.fetchByRecipient(id);
      commit("SET_FILTERED", mails);
    },
    async create({ commit }: any, payload: any) {
      const result = await MailsService.create(payload);
    },
    async update({ commit }: any, payload: any) {
      const result = await MailsService.update(payload.id, payload);
    },
    async delete({ commit }: any, id: string) {
      await MailsService.delete(id);
    },
    async sendMail({ commit }: any, payload: any) {
      const result = await MailsService.sendMail(payload);
    },
  },
} as any;

export default Mails;
