import UsersService from "@/services/UsersService";
import { PaginatedResult } from "@/shared/types/paginated-result.class";

interface UsersState {
  users: PaginatedResult<any>;
  currentUser: any;
}

const Users = {
  namespaced: true,
  state: {
    users: {} as PaginatedResult<any>,
    currentUser: {},
  },
  getters: {
    all: (state: UsersState) => state.users,
    current: (state: UsersState) => state.currentUser,
  },
  mutations: {
    SET_ALL(state: UsersState, users: PaginatedResult<any>) {
      state.users = users;
    },
    SET_CURRENT(state: UsersState, user: any) {
      state.currentUser = user;
    },
  },
  actions: {
    async fetchAll({ commit }: any, filter: any) {
      const users = await UsersService.fetchAll();
      commit("SET_ALL", users);
    },
    async fetchById({ commit }: any, id: string) {
      const user = await UsersService.fetchOne(id);
      commit("SET_CURRENT", user);
    },
    async delete({ commit }: any, id: string) {
      await UsersService.delete(id);
    },
    async update({ commit }: any, user: any) {
      const newUser = await UsersService.update(user.id, user);
    },
    async create({ commit }: any, user: any) {
      const newUser = await UsersService.create(user);
    },
    async add({ commit }: any, user : any) {
      const newUser = await UsersService.create(user)
    }
  },
} as any;

export default Users;
